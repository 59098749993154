<div class="row align-items-center" style="padding: 5px;">
    <div class="col-md-12 text-right">
        <div class="float-right" style="margin-right: 40px;">
            <h3 *ngIf="userName">Welcome, {{ userName }}</h3>
        </div>
    </div>
</div>

<div class="container py-3" *ngIf="isInitializing">
    <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
    </div>
</div>

<div class="container py-3" *ngIf="userName">
    <div class="row">
		<div class="col">
            <h1>
                {{ selectedLecturerName }}
            </h1>
		</div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <select [(ngModel)]="selectedAcademicYearID" (ngModelChange)="getLecturerReport()" class="form-control">
                <option value="" selected>Filter by academic Year</option>
                <option *ngFor="let academicYearReference of academicYearReferenceList" 
                    [value]="academicYearReference.id">
                    {{ academicYearReference.code }}
                </option>
            </select>
        </div>
        <div class="col-sm-4">
            <select [(ngModel)]="selectedSemesterID" (ngModelChange)="getLecturerReport()" class="form-control">
                <option value="" selected>Filter by semester</option>
                <option *ngFor="let semesterReference of semesterReferenceList" 
                    [value]="semesterReference.id">
                    {{ semesterReference.description }}
                </option>
            </select>
        </div>
        <div class="col-sm-4 text-right" style="padding-top: 5px;">
            Total SKS: {{ selectedLecturerTotalSKS }}
        </div>
    </div>
    <br>
    <div class="row">
		<table class="table table-bordered" matSort (matSortChange)="sortList($event)">
			<thead>
				<tr>
					<th style="text-align: center;">BKD</th>
					<th></th>
					<th style="text-align: center;">Description</th>
					<th style="text-align: center;">Keterangan 1</th>
					<th style="text-align: center;">Keterangan 2</th>
					<th style="text-align: center;">Keterangan 3</th>
					<th style="text-align: center;">SKS</th>
					<th style="text-align: center;">Required Documents</th>
					<th style="text-align: center;">File</th>
					<th style="text-align: center;">Approval</th>
					<th style="text-align: center;">Completion</th>
					<th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
                <ng-container *ngFor="let workloads of lecturerReport.workloads | keyvalue">
                    <ng-container *ngFor="let item of workloads.value; index as i">
                        <tr *ngFor="let doc of item.required_documents; index as j">
                            <td [attr.rowspan]="workloads.value.length * item.required_documents.length" *ngIf="i==0 && j==0" style="text-align: center;">
                                {{ item.code }}
                            </td>
                            <td [attr.rowspan]="item.required_documents.length" *ngIf="j==0">
                                <input type="checkbox" name="selected_workload" value="{{ workloads.key }}:{{ item.id }}" />
                            </td>
                            <td [attr.rowspan]="item.required_documents.length" *ngIf="j==0">
                                {{ item.info1 }}
                            </td>
                            <td [attr.rowspan]="item.required_documents.length" *ngIf="j==0">
                                {{ item.info2 }}
                            </td>
                            <td [attr.rowspan]="item.required_documents.length" *ngIf="j==0">
                                {{ item.info3 }}
                            </td>
                            <td [attr.rowspan]="item.required_documents.length" *ngIf="j==0">
                                {{ item.info4 }}
                            </td>
                            <td [attr.rowspan]="item.required_documents.length" *ngIf="j==0" style="text-align: center;">
                                {{ item.value }}
                            </td>
                            <td>
                                {{ doc.description }}
                            </td>
                            <td>
                                <div class="row text-center">
                                    <div *ngIf="doc.url" class="col" style="padding: 0px;">
                                        <a href="{{ doc.url }}" target="_blank" class="btn btn-light" style="margin-bottom: 5px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td [attr.rowspan]="item.required_documents.length" *ngIf="j==0" class="text-center">
                                <svg *ngIf="item.is_approved===1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                </svg>
                                <svg *ngIf="item.is_approved===0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                </svg>
                            </td>
                            <td [attr.rowspan]="item.required_documents.length" *ngIf="j==0" class="text-center">
                                <svg *ngIf="item.is_verified===1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                </svg>
                                <svg *ngIf="item.is_verified===0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                </svg>
                            </td>
                            <td [attr.rowspan]="item.required_documents.length" *ngIf="j==0" style="width: 100px;">
                                <div class="row text-center">
                                    <div *ngIf="getActionButtonAvailability(item)===0 || getActionButtonAvailability(item)===null" class="col" style="padding: 2px;">
                                        <button (click)="submit(workloads.key, item.id)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div *ngIf="getActionButtonAvailability(item)===1 || getActionButtonAvailability(item)===null" class="col" style="padding: 2px;">
                                        <button (click)="cancel(workloads.key, item.id)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
			</tbody>
		</table>
    </div>
    <div class="row">
        <div class="col">
            <button (click)="getLecturerAgreement()" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false" style="margin-right: 10px;">
                Generate Contract <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-lock" viewBox="0 0 16 16">
                    <path d="M10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 1 1 4 0zM7 7v1h2V7a1 1 0 0 0-2 0zM6 9.3v2.4c0 .042.02.107.105.175A.637.637 0 0 0 6.5 12h3a.64.64 0 0 0 .395-.125c.085-.068.105-.133.105-.175V9.3c0-.042-.02-.107-.105-.175A.637.637 0 0 0 9.5 9h-3a.637.637 0 0 0-.395.125C6.02 9.193 6 9.258 6 9.3z"/>
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                </svg>
            </button>
            <button (click)="getLecturerAddendum()" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Generate Addendum <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                    <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z"/>
                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                </svg>
            </button>
        </div>
        <div class="col text-right">
            <button (click)="cancelAll()" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false" style="margin-right: 10px;">
                Reject all <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </button>
            <button (click)="submitAll()" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Approve all <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                </svg>
            </button>
        </div>
    </div>
</div>

<!-- Form Modal -->
<div class="modal fade" id="form-modal" data-backdrop="false" tabindex="-1" aria-labelledby="form-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="form-modal-label">Process</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="hasSuccessfullySubmitted">
                    <div class="d-flex justify-content-center">
                        <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 70 70">
                            <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                            <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="green"/>
                            <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
                        </svg>
                    </div>
                    <br>
                    <div class="form-group row">
                      <div class="col-sm-12 text-center">
                        Successful. <span *ngIf="letterLink"><a [href]="sanitize(letterLink)" target="_blank">Click here</a> if you are not redirected.</span>
                      </div>
                    </div>
                </div>
                <div *ngIf="isSubmitting">
                    <div class="sk-folding-cube">
                        <div class="sk-cube1 sk-cube"></div>
                        <div class="sk-cube2 sk-cube"></div>
                        <div class="sk-cube4 sk-cube"></div>
                        <div class="sk-cube3 sk-cube"></div>
                    </div>
                </div>
                <div *ngIf="!hasSuccessfullySubmitted">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label style="color: red;">{{ errorMessage }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
